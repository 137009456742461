<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Autocomplete</h2>
    </template>
    <template #page-content>
      <r-card>
        <h3>Overview</h3>
        <p>
          Combo Box menggabungkan bidang teks dan tarik-turun yang memberi orang
          cara untuk memilih opsi dari daftar atau memasukkan pilihan mereka
          sendiri.
        </p>
      </r-card>
      <r-card>
        <div class="columns">
          <div class="column is-2">
            <h4>Basic Autocomplete</h4>
            <b-autocomplete
              v-model="name"
              open-on-focus
              icon-right="chevron-down"
              :data="basicData"
              @select="(option) => (selected = option)"
            ></b-autocomplete>
            <br />
            <b-autocomplete
              v-model="disabled"
              disabled
              open-on-focus
              icon-right="chevron-down"
              :data="filteredDataObj"
              @select="(option) => (selected = option)"
            ></b-autocomplete>
          </div>
        </div>
        <div class="columns">
          <div class="column is-2">
            <h4>Grouped</h4>
            <b-autocomplete
              v-model="name"
              group-field="type"
              group-options="items"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredDataObj"
              @select="(option) => (selected = option)"
            ></b-autocomplete>
            <br />
            <b-autocomplete
              v-model="disabled"
              disabled
              group-field="type"
              group-options="items"
              open-on-focus
              icon-right="chevron-down"
              :data="filteredDataObj"
              @select="(option) => (selected = option)"
            ></b-autocomplete>
          </div>
        </div>
      </r-card>
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      choice: 'choice-2',
      imageChoice: 'image-choice-2',
      basicData: ['Apple', 'Banana', 'Watermelon'],
      basicName: '',
      comboBoxData: [
        {
          type: 'Fruit',
          items: ['Apple', 'Banana', 'Watermelon'],
        },
        {
          type: 'Vegetables',
          items: ['Carrot', 'Broccoli', 'Cucumber', 'Onion'],
        },
      ],
      name: '',
      disabled: 'Disabled',
    }
  },
  computed: {
    filteredDataObj() {
      const newData = []
      this.comboBoxData.forEach((element) => {
        const items = element.items.filter(
          (item) => item.toLowerCase().indexOf(this.name.toLowerCase()) >= 0
        )
        if (items.length) {
          newData.push({ type: element.type, items })
        }
      })
      return newData
    },
  },
  mounted() {
    // console.log('matched', this.$rVar)
  },
}
</script>
